<script setup>
import { defineEmits, defineProps } from "vue"

const emit = defineEmits(["onClose"])
const props = defineProps({
    content: { required: true, type: Object }
})
</script>

<template>
    <div
        class="
            flex
            justify-between
            items-center
            gap-x-6
            bg-bannerbg
            px-6
            py-2.5
            sm:px-3.5
        "
    >
        <div class="hidden sm:block"></div>
        <div class="text-sm leading-6 text-bannertxt">
            <div>
                <strong
                    v-html="props.content.title"
                    class="font-semibold"
                ></strong
                ><span v-html="props.content.message"></span>
            </div>
        </div>
        <div class="flex justify-end">
            <button
                @click="emit('onClose')"
                type="button"
                class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
                <span class="sr-only">Dismiss</span>
                <svg
                    class="h-5 w-5 text-bannertxt"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<style scoped></style>
