<template>
    <div>
        <MaintenanceBanner
            v-if="!isNaN(showMaintenance) && showMaintenance !== 0"
            @on-close="showMaintenance = 0"
            :content="maintenanceBannerContent"
        />
        <div
            v-if="roleList.length > 1 && !portalMode && !isLoading"
            class="
                h-screen
                w-screen
                flex flex-col
                justify-center
                items-center
                px-4
            "
        >
            <div class="bg-gray-100 w-full max-w-5xl p-16 rounded">
                <div class="text-center text-xl font-medium mb-10">
                    SELECT PORTAL TYPE
                </div>
                <div class="grid grid-cols-2 gap-x-16">
                    <div>
                        <div
                            class="
                                text-center text-yellow-400 text-xl
                                font-medium
                                mb-1
                            "
                        >
                            Hotel
                        </div>
                        <div class="flex justify-center">
                            <button
                                @click="selectHotel"
                                class="
                                    border border-yellow-400
                                    bg-white
                                    text-yellow-400
                                    rounded
                                    shadow-md
                                    p-8
                                    hover:bg-yellow-400 hover:text-white
                                    transition
                                    duration-200
                                    ease-linear
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                        stroke-current
                                        icon icon-tabler icon-tabler-bed
                                    "
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path
                                        d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"
                                    />
                                    <circle cx="7" cy="10" r="1" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div
                            class="
                                text-center text-yellow-400 text-xl
                                font-medium
                                mb-1
                            "
                        >
                            Party Room
                        </div>
                        <div class="flex justify-center">
                            <button
                                @click="selectPartyRoom"
                                class="
                                    border border-yellow-400
                                    bg-white
                                    text-yellow-400
                                    rounded
                                    shadow-md
                                    p-8
                                    hover:bg-yellow-400 hover:text-white
                                    transition
                                    duration-200
                                    ease-linear
                                "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                        stroke-current
                                        icon icon-tabler icon-tabler-confetti
                                    "
                                    width="44"
                                    height="44"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M4 5h2" />
                                    <path d="M5 4v2" />
                                    <path d="M11.5 4l-.5 2" />
                                    <path d="M18 5h2" />
                                    <path d="M19 4v2" />
                                    <path d="M15 9l-1 1" />
                                    <path d="M18 13l2 -.5" />
                                    <path d="M18 19h2" />
                                    <path d="M19 18v2" />
                                    <path
                                        d="M14 16.518l-6.518 -6.518l-4.39 9.58a1.003 1.003 0 0 0 1.329 1.329l9.579 -4.39z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="relative flex flex-row w-screen h-full">
            <transition
                enter-active-class="transition-all ease-in-out duration-500 transform"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-all ease-in-out duration-500 transform"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <NotifBanner
                    class="z-50"
                    v-if="
                        isNoti &&
                        ((roleList.length < 2 &&
                            !roleList.includes('Party Room')) ||
                            portalMode === 1)
                    "
                    :notification="notification"
                    @click="
                        $router.push({
                            name: 'Upcomingbooking',
                            params: {
                                role: user.portal_user_role_level
                            },
                            query: {
                                space_id: spaceID
                                    ? spaceID
                                    : user.space_info_list[0].space_id,
                                space_type: spaceType
                                    ? convertSpaceTypeName(spaceType)
                                    : convertSpaceTypeName(
                                          user.space_info_list[0].space_type
                                      )
                            }
                        })
                    "
                />
            </transition>
            <div class="sticky w-0 lg:w-auto h-screen top-0 z-10 lg:z-0">
                <Sidebar
                    ref="sidebar"
                    v-if="!isLoading"
                    :mode="portalMode"
                    :roles="roleList"
                    :space_id="this.spaceID"
                    :space_type="convertSpaceTypeName(this.spaceType)"
                    :user="user"
                    :idx="selectedSpaceIndex"
                    @onPress="switchSpace"
                    @onModeChange="changePortalMode"
                />
            </div>
            <div class="w-full min-h-screen h-full bg-gray-100 z-0">
                <router-view
                    v-if="!isLoading"
                    :user="user"
                    :spaceID="spaceID"
                    :spaceType="spaceType"
                    :selectedSpace="selectedSpaceIndex"
                    :mode="portalMode"
                    @localeUpdate="getUserInfo"
                    @openSidebar="openSidebar"
                />
                <div v-else class="flex h-screen justify-center items-center">
                    <Loader type="lg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import Sidebar from "@/components/Sidebar"
import NotifBanner from "@/components/NotifBanner"
import axios from "axios"
import Loader from "@/components/Loader"
import * as Sentry from "@sentry/browser"
import HotelPageList from "@/components/HotelPageList.vue"
import MaintenanceBanner from "@/components/MaintenanceBanner.vue"

export default {
    name: "Home",
    components: {
        MaintenanceBanner,
        HotelPageList,
        Loader,
        NotifBanner,
        Sidebar
    },
    data() {
        return {
            isAuthenticated: this.$auth0.isAuthenticated,
            isNoti: false,
            notification: 0,
            showMaintenance: 0,
            maintenanceBannerContent: {},
            isLoading: true,
            user: {},
            spaceID: "",
            spaceType: 0,
            selectedSpaceIndex: 0,
            portalMode: null,
            roleList:
                this.$auth0.user.value[
                    `${process.env.VUE_APP_AUTH0_AUDIENCE}/roles`
                ]
        }
    },
    methods: {
        convertSpaceType(type) {
            switch (type) {
                case "rest":
                    return 1
                case "work":
                    return 2
                case "meet":
                    return 3
                case "package":
                    return 4
                case "party":
                    return 5
            }
        },
        convertSpaceTypeName(type) {
            switch (type) {
                case 1:
                    return "rest"
                case 2:
                    return "work"
                case 3:
                    return "meet"
                case 4:
                    return "package"
                case 5:
                    return "party"
            }
        },
        openSidebar() {
            this.$refs.sidebar.isSidebar = true
            setTimeout(() => {
                this.$refs.sidebar.$refs.space_list.focus()
            }, 100)
        },
        async getUserInfo() {
            const url = `${process.env.VUE_APP_API_BASE_URL}/utils/getLoginInformation?lang=${this.$i18n.locale}`
            const res = await this.$axios.get(url)
            this.user = res.data.data
            Sentry.setUser({ email: this.user.portal_user_email })
            this.isLoading = false
        },
        getSpaceFromCookie() {
            if (this.portalMode === 1) {
                if (this.getCookie("selected_space")) {
                    let space = this.getCookie("selected_space")

                    let index = this.user.space_info_list.findIndex(
                        (e) =>
                            e.space_id === space.split("_")[0] &&
                            e.space_type === parseInt(space.split("_")[1])
                    )
                    if (index > -1) {
                        this.selectedSpaceIndex = index
                        this.spaceID = this.user.space_info_list[index].space_id
                        this.spaceType =
                            this.user.space_info_list[index].space_type
                    } else {
                        this.selectedSpaceIndex = 0
                        this.spaceID = this.user.space_info_list[0].space_id
                        this.spaceType = this.user.space_info_list[0].space_type
                    }
                } else {
                    this.selectedSpaceIndex = 0
                    this.spaceID = this.user.space_info_list[0].space_id
                    this.spaceType = this.user.space_info_list[0].space_type
                }
            } else if (this.portalMode === 2) {
                if (this.getCookie("selected_partyroom")) {
                    let space = this.getCookie("selected_partyroom")

                    let index = this.user.space_info_list.findIndex(
                        (e) =>
                            e.space_id === space.split("_")[0] &&
                            e.space_type === parseInt(space.split("_")[1])
                    )
                    if (index > -1) {
                        this.selectedSpaceIndex = index
                        this.spaceID = this.user.space_info_list[index].space_id
                        this.spaceType =
                            this.user.space_info_list[index].space_type
                    } else {
                        this.selectedSpaceIndex = 0
                        this.spaceID = this.user.space_info_list[0].space_id
                        this.spaceType = this.user.space_info_list[0].space_type
                    }
                } else {
                    let index = this.user.space_info_list.findIndex(
                        (e) => e.space_type === 5
                    )
                    this.selectedSpaceIndex = index
                    this.spaceID = this.user.space_info_list[index].space_id
                    this.spaceType = this.user.space_info_list[index].space_type
                }
            }
        },
        async notify() {
            const url = `${process.env.VUE_APP_API_BASE_URL}/utils/getNewReservation?space_id_list=${this.spaceID}`
            const res = await this.$axios.get(url)
            this.notification = res.data.data[this.spaceID]
            if (this.notification > 0) {
                this.isNoti = true
                if (Notification.permission === "granted") {
                    const myNoti = new Notification("Flow", {
                        body: `You have ${this.notification} new reservations.`,
                        icon: "https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                    })
                }
                setTimeout(() => {
                    this.isNoti = false
                }, 3000)
            }
        },
        // playSound() {
        //     let audio = new Audio(
        //         "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3"
        //     )
        //     audio.play()
        // },
        // isFocus() {
        //     let self = this
        //     document.addEventListener("visibilitychange", function (e) {
        //         if (document.visibilityState === "visible") {
        //             self.isHidden = true
        //         }
        //     })
        // },
        async switchSpace(e) {
            this.selectedSpaceIndex = parseInt(e)
            this.spaceID = this.user.space_info_list[e].space_id
            this.spaceType = this.user.space_info_list[e].space_type
            let d = new Date()
            d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
            let expires = "expires=" + d.toUTCString()
            if (this.portalMode === 1) {
                document.cookie =
                    "selected_space=" +
                    `${this.spaceID}_${this.spaceType}` +
                    ";" +
                    expires +
                    ";path=/;SameSite=Lax"
            }
            if (this.portalMode === 2) {
                document.cookie =
                    "selected_partyroom=" +
                    `${this.spaceID}_${this.spaceType}` +
                    ";" +
                    expires +
                    ";path=/;SameSite=Lax"
            }
            await this.$router.push({
                name: this.$route.name,
                params: {
                    role: this.user.portal_user_role_level
                },
                query: {
                    space_id: this.spaceID
                        ? this.spaceID
                        : this.user.space_info_list[0].space_id,
                    space_type: this.spaceType
                        ? this.convertSpaceTypeName(this.spaceType)
                        : this.convertSpaceTypeName(
                              this.user.space_info_list[0].space_type
                          )
                }
            })
        },
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) return parts.pop().split(";").shift()
        },
        selectHotel() {
            this.portalMode = 1
            this.getSpaceFromCookie()
            let d = new Date()
            d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
            let expires = "expires=" + d.toUTCString()
            document.cookie =
                "portal_mode=1" + ";" + expires + ";path=/;SameSite=Lax"
            this.$router.push({
                name: "Overview",
                params: {
                    role: this.user.portal_user_role_level
                },
                query: {
                    space_id: this.spaceID
                        ? this.spaceID
                        : this.user.space_info_list[0].space_id,
                    space_type: this.spaceType
                        ? this.convertSpaceTypeName(this.spaceType)
                        : this.convertSpaceTypeName(
                              this.user.space_info_list[0].space_type
                          )
                }
            })
        },
        selectPartyRoom() {
            this.portalMode = 2
            this.getSpaceFromCookie()
            let d = new Date()
            d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
            let expires = "expires=" + d.toUTCString()
            document.cookie =
                "portal_mode=2" + ";" + expires + ";path=/;SameSite=Lax"
            this.$router.push({
                name: "PDashboard",
                params: {
                    role: this.user.portal_user_role_level
                },
                query: {
                    space_id: this.spaceID
                        ? this.spaceID
                        : this.user.space_info_list[0].space_id,
                    space_type: this.spaceType
                        ? this.convertSpaceTypeName(this.spaceType)
                        : this.convertSpaceTypeName(
                              this.user.space_info_list[0].space_type
                          )
                }
            })
        },
        changePortalMode() {
            if (this.portalMode === 1) {
                this.selectPartyRoom()
            } else {
                this.selectHotel()
            }
        },
        async validatePath() {
            let s = -1
            if (this.$route.query.space_type) {
                s = this.user.space_info_list.findIndex(
                    (e) =>
                        e.space_id === this.$route.query.space_id &&
                        e.space_type ===
                            this.convertSpaceType(this.$route.query.space_type)
                )
            } else {
                s = this.user.space_info_list.findIndex(
                    (e) => e.space_id === this.$route.query.space_id
                )
            }
            if (s > -1) {
                this.selectedSpaceIndex = s
                this.spaceID = this.$route.query.space_id
                this.spaceType = this.convertSpaceType(
                    this.$route.query.space_type
                )
            }
            if (this.spaceType === 5) {
                this.portalMode = 2
                let d = new Date()
                d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
                let expires = "expires=" + d.toUTCString()
                document.cookie =
                    "portal_mode=2" + ";" + expires + ";path=/;SameSite=Lax"
                document.cookie =
                    "selected_partyroom=" +
                    `${this.spaceID}_${this.spaceType}` +
                    ";" +
                    expires +
                    ";path=/;SameSite=Lax"
                if (
                    this.$route.name === "Overview" ||
                    this.$route.name === "Dashboard" ||
                    this.$route.name === "Roomspecification" ||
                    !this.$route.name
                ) {
                    await this.$router.push({
                        name: "PDashboard",
                        params: {
                            role: this.user.portal_user_role_level
                        },
                        query: {
                            space_id: this.spaceID
                                ? this.spaceID
                                : this.user.space_info_list[0].space_id,
                            space_type: this.spaceType
                                ? this.convertSpaceTypeName(this.spaceType)
                                : this.convertSpaceTypeName(
                                      this.user.space_info_list[0].space_type
                                  )
                        }
                    })
                } else {
                    await this.$router.push({
                        name: this.$route.name,
                        params: {
                            role: this.user.portal_user_role_level
                        },
                        query: {
                            space_id: this.spaceID
                                ? this.spaceID
                                : this.user.space_info_list[0].space_id,
                            space_type: this.spaceType
                                ? this.convertSpaceTypeName(this.spaceType)
                                : this.convertSpaceTypeName(
                                      this.user.space_info_list[0].space_type
                                  )
                        }
                    })
                }
            }
            if (this.spaceType !== 5) {
                this.portalMode = 1
                let d = new Date()
                d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000)
                let expires = "expires=" + d.toUTCString()
                document.cookie =
                    "portal_mode=1" + ";" + expires + ";path=/;SameSite=Lax"
                document.cookie =
                    "selected_space=" +
                    `${this.spaceID}_${this.spaceType}` +
                    ";" +
                    expires +
                    ";path=/;SameSite=Lax"
                if (this.$route.name === "PDashboard" || !this.$route.name) {
                    await this.$router.push({
                        name: "Overview",
                        params: {
                            role: this.user.portal_user_role_level
                        },
                        query: {
                            space_id: this.spaceID
                                ? this.spaceID
                                : this.user.space_info_list[0].space_id,
                            space_type: this.spaceType
                                ? this.convertSpaceTypeName(this.spaceType)
                                : this.convertSpaceTypeName(
                                      this.user.space_info_list[0].space_type
                                  )
                        }
                    })
                } else {
                    await this.$router.push({
                        name: this.$route.name,
                        params: {
                            role: this.user.portal_user_role_level
                        },
                        query: {
                            space_id: this.spaceID
                                ? this.spaceID
                                : this.user.space_info_list[0].space_id,
                            space_type: this.spaceType
                                ? this.convertSpaceTypeName(this.spaceType)
                                : this.convertSpaceTypeName(
                                      this.user.space_info_list[0].space_type
                                  )
                        }
                    })
                }
            }
        },
        async checkMaintenance() {
            const url = `${process.env.VUE_APP_API_BASE_WORKERS_URL}/index/maintenance?lang=${this.$i18n.locale}`
            const res = await this.$axios.get(url).catch((error) => {
                if (error.response) {
                    // Request made and server responded
                } else if (error.request) {
                    // The request was made but no response was received
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
            this.showMaintenance = res.data.status
            if (this.showMaintenance !== 0) {
                this.maintenanceBannerContent = res.data.banner.content
            }
        }
    },
    async mounted() {
        if (this.isAuthenticated) {
            const auth = this.$auth0
            axios.interceptors.request.use(async (config) => {
                try {
                    const token = await auth.getAccessTokenSilently()
                    config.headers.Authorization = `Bearer ${token}`
                    return config
                } catch (e) {
                    if (e.error === "login_required") {
                        await auth.loginWithRedirect()
                    }
                    if (e.error === "consent_required") {
                        await auth.loginWithRedirect()
                    }
                    return config
                }
            })
        } else {
            await this.$router.push({
                name: "Login",
                params: { lang: "en" }
            })
        }
        await this.getUserInfo()
        if (this.getCookie("portal_mode")) {
            let m = this.getCookie("portal_mode")
            if (m === "1") {
                this.portalMode = 1
                this.getSpaceFromCookie()
                await this.validatePath()
            }
            if (m === "2") {
                this.portalMode = 2
                this.getSpaceFromCookie()
                await this.validatePath()
            }
        }
        if (this.roleList.length < 2) {
            if (this.roleList.includes("Party Room")) {
                this.portalMode = 2
                this.getSpaceFromCookie()
                await this.validatePath()
            } else {
                this.portalMode = 1
                await this.validatePath()
            }
        }
        await this.checkMaintenance()
        await this.notify()
        setInterval(async () => {
            await this.notify()
        }, 180000)
    }
}
</script>
