<template>
    <div @click="onClick" class="absolute w-full cursor-pointer">
        <div class="flex justify-center">
            <div class="relative w-full flex-wrap shadow-lg bg-blue-500 p-4">
                <div class="flex">
                    <div class="ml-3 flex-1 lg:flex lg:justify-between">
                        <p class="text-sm text-blue-100">
                            You have {{ notification }} new reservations. Click
                            me to check the new reservations.
                        </p>
                        <div
                            class="flex text-white mt-3 text-sm lg:mt-0 lg:mr-5"
                        >
                            <button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                        mr-6
                                        stroke-current
                                        icon
                                        icon-tabler
                                        icon-tabler-arrow-narrow-right
                                    "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                    <line x1="15" y1="16" x2="19" y2="12" />
                                    <line x1="15" y1="8" x2="19" y2="12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="absolute top-1 right-2">
                    <button @click.stop="onCancel">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-x"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#ffffff"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotifBanner",
    props: {
        notification: { required: true, type: Number }
    },
    emits: ["onClick", "onCancel"],
    methods: {
        onClick() {
            this.notifyMe()
            this.$emit("onClick")
        },
        onCancel() {
            this.$emit("onCancel")
        },
        notifyMe() {
            // Let's check if the browser supports notifications
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification")
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                // const myNoti = new Notification("Flow", {
                //     body: `You have ${this.notification} new reservations.`,
                //     icon: "https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                // })
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        const myNoti = new Notification("Flow", {
                            body: `You will now receive our notification.`,
                            icon: "https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                        })
                    }
                })
            }

            // At last, if the user has denied notifications, and you
            // want to be respectful there is no need to bother them any more.
        }
    }
}
</script>

<style scoped></style>
