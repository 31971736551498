<template>
    <div class="lg:h-screen lg:flex">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <transition
            enter-active-class="transition-opacity ease-linear duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isSidebar"
                class="fixed inset-0 flex z-40 lg:hidden"
                role="dialog"
                aria-modal="true"
            >
                <transition
                    enter-active-class="transition-opacity ease-linear duration-200"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity ease-linear duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    ><div
                        v-if="isSidebar"
                        @click="isSidebar = false"
                        class="fixed inset-0 bg-gray-600 bg-opacity-75"
                        aria-hidden="true"
                    ></div
                ></transition>

                <transition
                    enter-active-class="transition ease-in-out duration-300 transform"
                    enter-from-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transition ease-in-out duration-300 transform"
                    leave-from-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                >
                    <div
                        v-if="isSidebar"
                        class="
                            relative
                            z-auto
                            flex flex-col
                            max-w-xs
                            w-full
                            bg-gray-800
                        "
                    >
                        <transition
                            enter-active-class="ease-in-out duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-in-out duration-300"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="isSidebar"
                                class="absolute top-0 right-0 -mr-12 pt-2"
                            >
                                <button
                                    type="button"
                                    @click="isSidebar = false"
                                    class="
                                        ml-1
                                        flex
                                        items-center
                                        justify-center
                                        h-10
                                        w-10
                                        rounded-full
                                        focus:outline-none
                                        focus:ring-2
                                        focus:ring-inset
                                        focus:ring-white
                                    "
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <!-- Heroicon name: outline/x -->
                                    <svg
                                        class="h-6 w-6 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </transition>
                        <div class="flex-1 h-0 pb-4 overflow-y-auto">
                            <div
                                class="py-16 px-4 flex flex-col justify-center"
                                style="
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                "
                                v-bind:style="{
                                    'background-image':
                                        'url(' +
                                        user.space_info_list[selectedIndex]
                                            .thumbnail_url +
                                        ')'
                                }"
                            >
                                <div
                                    class="text-center bg-white"
                                    v-if="user.space_info_list.length === 1"
                                >
                                    {{
                                        user.space_info_list[0][
                                            `name_${$i18n.locale}`
                                        ]
                                    }}
                                </div>
                                <input
                                    v-if="user.space_info_list.length > 1"
                                    v-model="keyword"
                                    placeholder="Filter the space list by typing here"
                                    class="focus:outline-none pl-2 mb-2"
                                />
                                <select
                                    ref="space_list"
                                    v-if="user.space_info_list.length > 1"
                                    @change="onPress($event.target.value)"
                                    class="
                                        border
                                        rounded
                                        border-white
                                        text-sm
                                        lg:text-base
                                        truncate
                                    "
                                >
                                    <option v-if="selSpace.space_id && keyword">
                                        {{
                                            selSpace
                                                ? `${
                                                      selSpace[
                                                          `name_${$i18n.locale}`
                                                      ]
                                                          ? selSpace[
                                                                `name_${$i18n.locale}`
                                                            ]
                                                          : null
                                                  } - ${showSpaceType(
                                                      selSpace.space_type
                                                  )}`
                                                : null
                                        }}
                                    </option>
                                    <option
                                        v-for="(space, index) in spaceList"
                                        :key="index"
                                        :value="index"
                                        :selected="
                                            parseInt(selectedIndex) ===
                                            this.user.space_info_list.findIndex(
                                                (x) =>
                                                    x.space_id ===
                                                        this.spaceList[index]
                                                            .space_id &&
                                                    x.space_type ===
                                                        this.spaceList[index]
                                                            .space_type
                                            )
                                        "
                                    >
                                        {{
                                            space
                                                ? `${
                                                      space[
                                                          `name_${$i18n.locale}`
                                                      ]
                                                          ? space[
                                                                `name_${$i18n.locale}`
                                                            ]
                                                          : null
                                                  } - ${showSpaceType(
                                                      space.space_type
                                                  )}`
                                                : null
                                        }}
                                    </option>
                                </select>
                            </div>
                            <!--                            <div-->
                            <!--                                v-if="mode === 2"-->
                            <!--                                class="py-16 px-4 flex flex-col justify-center"-->
                            <!--                                style="-->
                            <!--                                    background-repeat: no-repeat;-->
                            <!--                                    background-size: cover;-->
                            <!--                                    background-image: url('https://media.istockphoto.com/photos/party-concept-balloons-in-living-room-picture-id1283683027?k=20&m=1283683027&s=612x612&w=0&h=_fJ4H2QD-jC7RApG4D8rTSbURa-oOD9Fwv7AM4hY99Y=');-->
                            <!--                                "-->
                            <!--                            ></div>-->
                            <!--                            <div class="flex-shrink-0 flex items-center px-4">-->
                            <!--                                <img-->
                            <!--                                    class="h-auto w-28"-->
                            <!--                                    src="https://res.cloudinary.com/flowtheroom/image/upload/v1618154583/icon/flow_s_light_g7vaij.png"-->
                            <!--                                    alt="Workflow"-->
                            <!--                                />-->
                            <!--                            </div>-->
                            <HotelPageList
                                v-if="mode === 1"
                                :user="user"
                                :roles="roles"
                                :space_id="space_id"
                                :space_type="space_type"
                                @onPress="this.isSidebar = false"
                                @onModeChange="onModeChange"
                            />
                            <PartyRoomPageList
                                v-if="mode === 2"
                                :user="user"
                                :roles="roles"
                                :space_id="space_id"
                                :space_type="space_type"
                                @onPress="this.isSidebar = false"
                                @onModeChange="onModeChange"
                            />
                        </div>
                        <div class="flex-shrink-0 flex bg-gray-700 p-4">
                            <a class="flex-shrink-0 group block">
                                <div class="flex items-center">
                                    <div>
                                        <img
                                            class="
                                                inline-block
                                                h-10
                                                w-10
                                                rounded-full
                                            "
                                            :src="
                                                this.$auth0.user.value.picture
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div class="ml-3">
                                        <p
                                            class="
                                                text-base
                                                font-medium
                                                text-white
                                            "
                                        >
                                            {{
                                                this.$auth0.user.value.nickname.toUpperCase()
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </transition>
                <div class="flex-shrink-0 w-14">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </transition>
        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div
                    class="
                        flex flex-col
                        h-0
                        flex-1
                        bg-gray-800
                        w-auto
                        lg:w-60
                        desktop:w-80
                    "
                >
                    <div class="flex-1 flex flex-col pb-4 overflow-y-auto">
                        <!--                        <div class="flex items-center flex-shrink-0 px-4">-->
                        <!--                            <img-->
                        <!--                                class="h-auto w-28"-->
                        <!--                                src="https://res.cloudinary.com/flowtheroom/image/upload/v1618154583/icon/flow_s_light_g7vaij.png"-->
                        <!--                                alt="Workflow"-->
                        <!--                            />-->
                        <!--                        </div>-->
                        <div
                            class="py-16 px-4 flex flex-col justify-center"
                            style="
                                background-repeat: no-repeat;
                                background-size: cover;
                            "
                            v-bind:style="{
                                'background-image':
                                    'url(' +
                                    user.space_info_list[selectedIndex]
                                        .thumbnail_url +
                                    ')'
                            }"
                        >
                            <div
                                class="text-center bg-white"
                                v-if="user.space_info_list.length === 1"
                            >
                                {{
                                    user.space_info_list[0][
                                        `name_${$i18n.locale}`
                                    ]
                                }}
                            </div>
                            <input
                                v-if="user.space_info_list.length > 1"
                                v-model="keyword"
                                placeholder="Filter the space list by typing here"
                                class="focus:outline-none pl-2 mb-2"
                            />
                            <select
                                v-if="user.space_info_list.length > 1"
                                ref="space_list"
                                @change="onPress($event.target.value)"
                                class="
                                    border
                                    rounded
                                    border-white
                                    text-sm
                                    lg:text-base
                                    truncate
                                "
                            >
                                <option v-if="selSpace.space_id && keyword">
                                    {{
                                        selSpace
                                            ? `${
                                                  selSpace[
                                                      `name_${$i18n.locale}`
                                                  ]
                                                      ? selSpace[
                                                            `name_${$i18n.locale}`
                                                        ]
                                                      : null
                                              } - ${showSpaceType(
                                                  selSpace.space_type
                                              )}`
                                            : null
                                    }}
                                </option>
                                <option
                                    v-for="(space, index) in spaceList"
                                    :key="index"
                                    :value="index"
                                    :selected="
                                        parseInt(selectedIndex) ===
                                        this.user.space_info_list.findIndex(
                                            (x) =>
                                                x.space_id ===
                                                    this.spaceList[index]
                                                        .space_id &&
                                                x.space_type ===
                                                    this.spaceList[index]
                                                        .space_type
                                        )
                                    "
                                >
                                    {{
                                        space
                                            ? `${
                                                  space[`name_${$i18n.locale}`]
                                                      ? space[
                                                            `name_${$i18n.locale}`
                                                        ]
                                                      : null
                                              } - ${showSpaceType(
                                                  space.space_type
                                              )}`
                                            : null
                                    }}
                                </option>
                            </select>
                        </div>
                        <!--                        <div-->
                        <!--                            v-if="mode === 2"-->
                        <!--                            class="py-16 px-4 flex flex-col justify-center"-->
                        <!--                            style="-->
                        <!--                                background-repeat: no-repeat;-->
                        <!--                                background-size: cover;-->
                        <!--                                background-image: url('https://media.istockphoto.com/photos/party-concept-balloons-in-living-room-picture-id1283683027?k=20&m=1283683027&s=612x612&w=0&h=_fJ4H2QD-jC7RApG4D8rTSbURa-oOD9Fwv7AM4hY99Y=');-->
                        <!--                            "-->
                        <!--                        ></div>-->
                        <HotelPageList
                            v-if="mode === 1"
                            :user="user"
                            :roles="roles"
                            :space_id="space_id"
                            :space_type="space_type"
                            @onPress="this.isSidebar = false"
                            @onModeChange="onModeChange"
                        />
                        <PartyRoomPageList
                            v-if="mode === 2"
                            :user="user"
                            :roles="roles"
                            :space_id="space_id"
                            :space_type="space_type"
                            @onPress="this.isSidebar = false"
                            @onModeChange="onModeChange"
                        />
                    </div>
                    <div class="flex-shrink-0 flex bg-gray-700 p-4">
                        <a class="flex-shrink-0 w-full group block">
                            <div class="flex items-center">
                                <div>
                                    <img
                                        class="
                                            inline-block
                                            h-9
                                            w-9
                                            rounded-full
                                        "
                                        :src="this.$auth0.user.value.picture"
                                        alt=""
                                    />
                                </div>
                                <div class="ml-3">
                                    <p class="text-sm font-medium text-white">
                                        {{
                                            this.$auth0.user.value.nickname.toUpperCase()
                                        }}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <transition
            enter-active-class="transition-opacity ease-linear duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity ease-linear duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="!isSidebar"
                class="lg:hidden lg:pl-64 flex flex-col flex-1"
            >
                <div
                    class="
                        sticky
                        w-screen
                        flex
                        justify-between
                        items-center
                        top-0
                        z-40
                        lg:hidden
                        pt-2
                        sm:pl-3 sm:pt-3
                        bg-gray-700 bg-opacity-90
                    "
                >
                    <div class="flex flex-1">
                        <button
                            type="button"
                            @click="isSidebar = true"
                            class="
                                ml-2
                                mb-1
                                inline-flex
                                items-center
                                justify-center
                                rounded-md
                                text-white
                                focus:outline-none
                            "
                        >
                            <span class="sr-only">Open sidebar</span>
                            <!-- Heroicon name: outline/menu -->
                            <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="flex justify-center flex-1">
                        <img
                            class="h-auto w-16 mb-2"
                            alt="flow logo"
                            src="https://res.cloudinary.com/flowtheroom/image/upload/v1618154583/icon/flow_s_light_g7vaij.png"
                        />
                    </div>
                    <div class="flex-1"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import HotelPageList from "@/components/HotelPageList"
import PartyRoomPageList from "@/components/PartyRoomPageList"

export default {
    name: "Sidebar",
    components: { PartyRoomPageList, HotelPageList },
    props: {
        user: { required: true, type: Object },
        roles: { required: false, type: Array },
        space_id: { required: true, type: String },
        space_type: { required: true, type: String },
        mode: { required: false, type: Number },
        idx: { required: false, type: Number }
    },
    emits: ["onPress", "onModeChange"],
    data() {
        return {
            isLoading: true,
            isSidebar: false,
            selectedIndex: 0,
            keyword: "",
            selSpace: {}
        }
    },
    mounted() {
        if (this.idx && this.idx > -1) {
            this.selectedIndex = this.idx
        }
        this.selSpace = this.user.space_info_list[this.selectedIndex]
    },
    methods: {
        showSpaceType(num) {
            num = parseInt(num)
            switch (num) {
                case 1:
                    return "Rest"
                case 2:
                    return "Work"
                case 3:
                    return "Meet"
                case 4:
                    return "Package"
                case 5:
                    return "Partyroom"
            }
        },
        onPress(e) {
            let index = this.user.space_info_list.findIndex(
                (x) =>
                    x.space_id === this.spaceList[e].space_id &&
                    x.space_type === this.spaceList[e].space_type
            )
            if (index < 0) index = 1
            this.selSpace = this.spaceList[e]
            this.keyword = ""
            this.selectedIndex = index.toString()
            this.isSidebar = false
            this.$emit("onPress", this.selectedIndex)
        },
        onModeChange() {
            this.$emit("onModeChange")
        },
        logout() {
            this.$auth0.logout({ returnTo: window.location.origin })
        }
    },
    computed: {
        spaceList: function () {
            let vm = this
            let lang = this.$i18n.locale
            if (vm.mode === 2) {
                return vm.user.space_info_list.filter((space) => {
                    return (
                        space[`name_${lang}`]
                            .toLowerCase()
                            .indexOf(vm.keyword.toLowerCase()) !== -1 &&
                        space.space_type === 5
                    )
                })
            } else
                return vm.user.space_info_list.filter((space) => {
                    return (
                        space[`name_${lang}`]
                            .toLowerCase()
                            .indexOf(vm.keyword.toLowerCase()) !== -1 &&
                        space.space_type !== 5
                    )
                })
        }
    },
    watch: {
        idx(to, from, next) {
            if (this.idx && this.idx > -1) {
                this.selectedIndex = this.idx
            }
            this.keyword = ""
            this.selSpace = this.user.space_info_list[this.selectedIndex]
        }
    }
}
</script>

<style scoped></style>
